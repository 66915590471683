/* eslint-disable import/prefer-default-export */
import ArrowDownSquareOutlined from "./arrow-down-square-outlined.svg"
import ArrowRightUp from "./arrow-ne.svg"
import ArrowRightDown from "./arrow-se.svg"
import Bars from "./bars.svg"
import Blog from "./blog.svg"
import CaretDown from "./caret-down.svg"
import CaretUp from "./caret-up.svg"
import Check from "./check.svg"
import CheckSquare from "./check-square.svg"
import Checklist from "./checklist.svg"
import ChevronDown from "./chevron-down.svg"
import ChevronLeft from "./chevron-left.svg"
import ChevronRight from "./chevron-right.svg"
import ChevronUp from "./chevron-up.svg"
import ChevronsLeft from "./chevrons-left.svg"
import ChevronsRight from "./chevrons-right.svg"
import CloudUpload from "./cloud-upload.svg"
import Command from "./command.svg"
import Convert from "./convert.svg"
import Copy from "./copy.svg"
import Curriculum from "./curriculum.svg"
import DistributionHistory from "./distribution-history.svg"
import DotsHorizontal from "./dots-horizontal.svg"
import Envelope from "./envelope.svg"
import FileOutline from "./file-outline.svg"
import Filter from "./filter.svg"
import Globe from "./globe.svg"
import Headphone from "./headphone.svg"
import Help from "./help.svg"
import HelpOutline from "./help-outline.svg"
import History from "./history.svg"
import IDCard from "./id-card.svg"
import InfoCircle from "./info-circle.svg"
import InfoCircleOutline from "./info-circle-outline.svg"
import LeftArrow from "./left-arrow.svg"
import Location from "./location.svg"
import Lock from "./lock.svg"
import Logout from "./logout.svg"
import Mail from "./mail.svg"
import Map from "./map.svg"
import Markets from "./markets.svg"
import Moon from "./moon.svg"
import PlusCircleFilled from "./plus-circle-filled.svg"
import PlusSquareOutlined from "./plus-square-outlined.svg"
import Profile from "./profile.svg"
import Resources from "./resources.svg"
import Search from "./search.svg"
import Settings from "./settings.svg"
import Star from "./star.svg"
import Sun from "./sun.svg"
import SwapRight from "./swap-right.svg"
import SwapVertical from "./swap-vertical.svg"
import Switch from "./switch.svg"
import TimeCircle from "./time-circle.svg"
import Times from "./times.svg"
import Trade from "./trade.svg"
import UserCheck from "./user-check.svg"
import UserCircle from "./user-circle.svg"
import Wallet from "./wallet.svg"
import WalletHistory from "./wallet-history.svg"
import WalletOutlined from "./wallet-outlined.svg"
import Work from "./work.svg"

export {
    ArrowDownSquareOutlined,
    ArrowRightDown,
    ArrowRightUp,
    Bars,
    Blog,
    CaretDown,
    CaretUp,
    CheckSquare,
    Check,
    Checklist,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ChevronsLeft,
    ChevronsRight,
    CloudUpload,
    Command,
    Convert,
    Copy,
    DistributionHistory,
    DotsHorizontal,
    Curriculum,
    Envelope,
    Filter,
    FileOutline,
    Globe,
    Headphone,
    Help,
    HelpOutline,
    History,
    IDCard,
    InfoCircle,
    InfoCircleOutline,
    LeftArrow,
    Lock,
    Location,
    Logout,
    Mail,
    Map,
    Markets,
    Moon,
    PlusCircleFilled,
    PlusSquareOutlined,
    Profile,
    Resources,
    Search,
    Settings,
    Star,
    Sun,
    SwapRight,
    SwapVertical,
    Switch,
    Times,
    TimeCircle,
    Trade,
    UserCheck,
    UserCircle,
    Wallet,
    WalletHistory,
    WalletOutlined,
    Work
}
