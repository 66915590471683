/* eslint-disable react/jsx-props-no-spreading */
import styled from "@emotion/styled"

import * as IconComponents from "./images"

const StyledIcon = styled.div`
    width: fit-content;
    height: fit-content;

    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export interface IconsProps extends React.SVGProps<SVGSVGElement> {
    /**
     * Icon name
     */
    icon: keyof typeof IconComponents | React.ReactElement
}

const Icons: React.FC<IconsProps> = ({ icon, ...props }: IconsProps) => {
    const Component = typeof icon === "string" ? IconComponents[icon] : icon

    return (
        <StyledIcon>
            <Component {...props} />
        </StyledIcon>
    )
}

Icons.defaultProps = {
    width: 16,
    height: 16
}

export default Icons
